import axios, { AxiosInstance } from "axios";
import { environments } from "../../../config/enviroment";


class HttpService {
    private static instance: HttpService | null = null;
    private _httpClient: AxiosInstance;

    private constructor() {
        this._httpClient = axios.create({
            baseURL: environments.baseUrl
        });
    }

    private static getInstance(): HttpService {
        if (!HttpService.instance) {
            HttpService.instance = new HttpService();
        }
        return HttpService.instance;
    }

    public static get httpClient(): AxiosInstance {
        return HttpService.getInstance()._httpClient;
    }
}

export default HttpService;