import logo from "../assets/logo.svg";
import menuIcon from "../assets/menu.svg";
import loader from "../assets/loading.svg";
import "./App.scss";
import { Subscription } from "rxjs";
import { QHeader, QToastr, QWaiting } from "../lib/react";
import { WidgetToastr } from "@qilab/widgets";
import { createRef, useEffect, useState } from "react";

import {
  authorizationInterceptor,
  HttpHeaderType,
} from "./shared/interceptors/auth.interceptor";
import { errorInterceptor } from "./shared/interceptors/error.interceptor";
import { loadingInterceptor } from "./shared/interceptors/loading.interceptor";
import HttpService from "./shared/services/http.service";
import LoaderService from "./shared/services/loader.service";
import { SidebarItems } from "./shared/services/sidebar.service";
import ToastrService from "./shared/services/toastr.service";
import { LandingComponent } from "./pages/landing";
import { cookieStorage, getQueryParam } from "@qilab/tools";

const App = (): JSX.Element => {
  const [hasCookie, setHasCookie] = useState<boolean>(false);
  const toastrRef = createRef<WidgetToastr>();
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setMenuItems(SidebarItems);
    const toastrSubscription = toastrNotifications();
    const loadingSubscription = loaderNotifications();
    const errorSubscription = errorInterceptor();
    const loadingInterceptorSubscription = loadingInterceptor();
    const authInterceptorSubscription = authorizationInterceptor(
      HttpHeaderType.AUTH,
      "Bearer MyJwtTestToken",
    );

    return () => {
      toastrSubscription.unsubscribe();
      loadingSubscription.unsubscribe();
      HttpService.httpClient.interceptors.response.eject(errorSubscription);
      HttpService.httpClient.interceptors.request.eject(
        loadingInterceptorSubscription,
      );
      HttpService.httpClient.interceptors.request.eject(
        authInterceptorSubscription,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkAuthentication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAuthentication = async () => {
    /* Controlla se esiste il cookie QilabAuth */
    if (cookieStorage.get("QiLabAuth")) {
      setHasCookie(true);
      return;
    }

    /* Controlla se nella querystring dell'url e' presente il parametro authToken */
    if (getQueryParam("authToken")) {
      const auth = getQueryParam("authToken");
      cookieStorage.set("QiLabAuth", auth, "qilab.it");
      setHasCookie(true);
      return;
    }

    if (getQueryParam("access_token")) {
      const auth = getQueryParam("access_token");
      cookieStorage.set("QiLabAuth", auth, "qilab.it");
      setHasCookie(true);
      return;
    }

    // get from hash fragment
    var url_fragment = window.location.hash.substring(1);
    if (url_fragment) {
      const params = new URLSearchParams(url_fragment);
      if (params.has("access_token")) {
        const auth = params.get("access_token");
        cookieStorage.set("QiLabAuth", auth, "qilab.it");
        setHasCookie(true);
        return;
      }
    }
    window.location.href = 'https://extranet.quidinfo.it/qinetic-security/landing.jsp?target=https://portal.qilab.it&directToken=true';
  };

  const toastrNotifications = (): Subscription => {
    return ToastrService.toastr$.subscribe((error) => {
      toastrRef.current.addMessage(error.type, error.title, error.message);
    });
  };

  const loaderNotifications = (): Subscription => {
    return LoaderService.loading$.subscribe((loading) => {
      setLoading(loading);
    });
  };

  return (
    <>
      <QHeader
        logo={logo}
        menuIcon={menuIcon}
        items={menuItems}
        isMobile={false}
      >
        <div></div>
      </QHeader>
      <div className="container-fluid d-flex flex-column ">
        {hasCookie ? <LandingComponent></LandingComponent> : undefined}
      </div>
      <QToastr ref={toastrRef} destroyTimer="5000"></QToastr>
      <QWaiting src={loader} show={loading}></QWaiting>
    </>
  );
};

export default App;
