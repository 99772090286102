import HttpService from "../services/http.service";
import LoaderService from "../services/loader.service";

export const loadingInterceptor = (): number => {
    return HttpService.httpClient.interceptors.request.use(
        request => {
            LoaderService.loading$.next(true);
            return request;
        },
        undefined
    );
}