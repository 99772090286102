import { QCard } from "../../../lib/react";
import { SidebarItems } from "../../shared/services/sidebar.service";
import './index.scss';
export const LandingComponent = (): JSX.Element => {
    return <div className="container d-flex flex-column">
        <div className="row flex-grow-1">
            {
                SidebarItems.map(item => {
                    return (
                        <div className="col-lg-4 col-sm-6 col-12 mt-5 px-3 portal-card">
                            <QCard customMode={true} onClick={() => { window.open(item.path, '_blank') }}>
                                <div className="row ">
                                    <div className="col d-flex justify-content-center">
                                        <img
                                            alt="resource guru"
                                            className="logo"
                                            src={item.icon}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3 ">
                                    <div className="col d-flex justify-content-center">
                                        <h4>{item.title}</h4>
                                    </div>
                                </div>
                            </QCard>
                        </div>
                    )
                })
            }


        </div>
    </div>
}
