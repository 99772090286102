import HttpService from "../services/http.service";


export enum HttpHeaderType {
    COOKIE = 'Cookie',
    AUTH = 'Authorization'
};

export const authorizationInterceptor = (type: HttpHeaderType, value: string): number => {
    return HttpService.httpClient.interceptors.request.use(
        request => {
            request.headers[type] = `${value}`;
            return request;
        },
        undefined
    );

};