import HttpService from "../services/http.service";
import LoaderService from "../services/loader.service";
import ToastrService from "../services/toastr.service";


export const errorInterceptor = (): number => {
    return HttpService.httpClient.interceptors.response.use(
        response => {
            LoaderService.loading$.next(false);
            return response;
        },
        error => {
            LoaderService.loading$.next(false);
            switch (error.response.status) {
                case 400:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.code
                    });
                    break;

                case 401:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.code
                    });

                    break;

                case 403:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.codes
                    });
                    break;

                case 404:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.code
                    });
                    break;

                case 500:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.code
                    });
                    break;

                default:
                    ToastrService.toastr$.next({
                        type: 'error',
                        title: error.response.status,
                        message: error.code
                    });
                    break;
            }
        },

    );
}