import { Subject } from "rxjs";

class LoaderService {
  private static instance: LoaderService | null = null;
  private _loading$: Subject<boolean>;

  private constructor() {
    this._loading$ = new Subject();
  }

  private static getInstance(): LoaderService {
    if (!LoaderService.instance) {
      LoaderService.instance = new LoaderService();
    }
    return LoaderService.instance;
  }

  public setLoading(loading: boolean): void {
    this._loading$.next(loading);
  }

  public static get loading$(): Subject<boolean> {
    return LoaderService.getInstance()._loading$;
  }
}

export default LoaderService;