import { Subject } from "rxjs";

class ToastrService {
  private static instance: ToastrService | null = null;
  private _toastr$: Subject<{
    type: string;
    title: string;
    message: string;
  }>;

  private constructor() {
    this._toastr$ = new Subject();
  }

  public static getInstance(): ToastrService {
    if (!ToastrService.instance) {
      ToastrService.instance = new ToastrService();
    }
    return ToastrService.instance;
  }

  public setMessage(message: {
    type: string;
    title: string;
    message: string;
  }): void {
    this._toastr$.next(message);
  }

  public static get toastr$(): Subject<{
    type: string;
    title: string;
    message: string;
  }> {
    return ToastrService.getInstance()._toastr$;
  }
}

export default ToastrService;
